import React, {useState, useEffect, useRef} from 'react';
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import TableComponent from '../../Atoms/TableComponent';
import { Check, Clear, Event } from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddTaskIcon from '@mui/icons-material/AddTask';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import ApprovalIcon from '@mui/icons-material/Approval';
import Tooltip from '@mui/material/Tooltip';
import { url } from '../../../Config';
import axios from 'axios';



export default function AnnouncementDashboard(props) {


    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState(); 
    const [data, setdata] = useState([]);
    const [bool, setbool] = useState(false);
    const Navigate = useNavigate();

    useEffect(()=>{
        setdata([])
        const func = async ()=>{
            const res = await axios.get(`${url}/api/view_announcement_data`);
            const resp = res.data;
            setdata(resp);
        }
        func();
      },[bool])

      

    const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
    
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
    
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };

      const deleteitem = async(ids)=>{
        Swal.fire({ title: 'Do you want to delete this Record?', 
        showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
        .then(async(result) => {
            
          if (result.isConfirmed) {
            const res = await axios.post(`${url}/api/delete_announcement`,{
                'id': ids
            });
            const resp = res.data;
            if(resp.status == true){
                setalertOpen(true);
                setalertmessage(resp.message);
                setalertType("success");
                setbool(!bool);
            }
            else{
                setalertOpen(true);
                setalertmessage(resp.message);  
                setalertType("error")
            }
          }
        })
        
    }
    
    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Title",
          minWidth: 250,
          field: "title",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "name",
          pinned: responsiveColumnPin(),
        },
    
        {
            headerName: "Upload Date",
            field: "upload_date",
            minWidth: 115,
            // hide: true,
            sortable: true,
            filter: "agDateColumnFilter",
            excelMode: "windows",
            cellRenderer: fullDate,
            debounceMs: "DateFilter",
            hide: columnHide(),
            filterParams: {
              filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
              inRangeInclusive: true,
              comparator: function (filterLocalDateAtMidnight, cellValue) {
                var dateAsString = moment(cellValue).format("DD/MM/YYYY");
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
      
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
      
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
      
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              },
              buttons: ["clear", "reset", "apply"],
              headerComponentParams: {
                template:
                  '<div class="ag-cell-label-container" role="presentation">' +
                  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                  '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                  "  </div>" +
                  "</div>",
              },
            },
        },
        {
            headerName: "Document Type",
            minWidth: 250,
            field: "doc_title",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: cellrander,
            tooltipField: "name",
            pinned: responsiveColumnPin(),
        },

        {
            headerName: "Report",
            field: "report_path",
            hide: columnHide(),
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            quickFilterText: "string",
            cellRenderer: (params) => {
              if (params.value === "nullData") return <Skeleton />;
              if (params.value) {
                return (
                  <a
                    href={`https://209.97.168.200/pacrawizpackv3/public/api/announcement_report/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Check style={{ size: "20 20" }} className="theme_text" />{" "}
                  </a>
                );
              } else {
                return <Clear className="theme_text" />;
              }
            },
          },
    
        {
            headerName: "Status",
            minWidth: 250,
            field: "status",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(params.value == 'd'){
                    return(
                        <div className='text-capitalize' style={{color: 'green'}}>Approved</div>
                    )
                }

                else{
                    return(
                        <div className='text-capitalize'>{params.value}</div>
                    )
                }
            },
            tooltipField: "status",
          },
    
        {
            headerName: "Action",
            minWidth: 250,
            field: "",
            sortable: true,
            filter: "agSetColumnFilter",
            excelMode: "windows",
            cellRenderer: (params)=>{
                if(params.data.status != 'd'){
                    return(
                        <div>
                          
                            {/* <Tooltip title="Approve"><button className='btn btn-success btn-sm me-1 mb-1' onClick={()=>{approve(params.data.id)}}><ApprovalIcon /></button></Tooltip> */}
                            <Tooltip title="Edit"><EditIcon sx={{color: 'yellow'}} onClick={()=>{Navigate(`/edit-announcement/${params.data.id}`)}} /></Tooltip>
                            <Tooltip title="Delete"><DeleteIcon sx={{color: 'red'}} onClick={()=>{deleteitem(params.data.id)}}/></Tooltip>
                        </div>
                    )
                }
                else{
                    return(
                        null
                    )
                }
                
            },
          },
             
      ];
    
        const MobViewRender = (params) => (
          <h1 style={{ padding: "10px 20px" }}>
            
          </h1>
        );


  return (
    <div>
      <div className='mt-2 text-center mb-2 fw-bold fs-3' style={{color:"#000078"}}>
        PACRA Announcments
      </div>
      <div className='mt-3 ms-2'>
        <button className='btn btn-primary' onClick={()=>{Navigate(`/add-announcement`)}}>Add New Announcment</button>
      </div>
      <hr />
      <div>
            <Box className="mx-auto mb-1 mt-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>
            <TableComponent
                Data={data}
                screenWidth={props.screenWidth}
                MobViewRender={MobViewRender}
                columnDefs={columnDefs}
                datefilters={datefilters}
                datefilter={false}
                announcement = {true}
            />
      </div>

    </div>
  )
}
