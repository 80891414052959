import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';


import { url } from '../../../Config';

export default function Edit_Rating_transition(props) {
    const [data, setdata] = useState([]);
    const { id } = useParams();
    const [alertopen, setalertOpen] = useState(false);
    const [bool, setbool] = useState(false);
    const [alertType, setalertType] = useState('success');
    const [AlertMessage, setalertmessage] = useState('');
    const userid = localStorage.getItem("userID");
    const [type, settype] = useState('Rating Transitions/Movements');
    const [title, settitle] = useState('Rating Transition Study |');
    

    
  
    const titleRef = useRef(null);
    const typeRef = useRef(null);
    const reportRef = useRef(null);
    const dateRef = useRef(null);
    const btnRef = useRef(null);
    const approveRef = useRef(null);
  
  

    
    useEffect(()=>{

        const func = async ()=>{
            if(btnRef.current){
                btnRef.current.disabled = true;
                btnRef.current.innerHTML = "Please Wait";
    
            }
            const res =  await axios.get(`${url}/api/get_edit_data/${id}`);
            const resp = res.data;
            if(resp.length > 0){
                setdata(resp[0]);
                if(typeRef.current){
                    typeRef.current.value = resp[0].doc_title;
                }
                if(dateRef.current){
                    dateRef.current.value = resp[0].upload_date;
                }

                if(resp[0].status == 'd'){
                    if(approveRef.current){
                        approveRef.current.hidden = true;
                    }
                }
                else if(resp[0].status == 'Entered'){
                    if(approveRef.current){
                        approveRef.current.hidden = false;
                    }
                }
            }
            if(btnRef.current){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML = "Update";
            }

        }

        func();

    },[!bool])


    const approve = async ()=>{
        if(approveRef.current){
            approveRef.current.disabled = true;
        }
        const res = await axios.post(`${url}/api/approve_announcement`,{
            'id': id,
            'type': type,
            'title': title

        });
        const resp = res.data;
        if(resp.status == true){
            if(approveRef.current){
                approveRef.current.disabled = false;
            }
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
            setbool(!bool);
        }
        else{
            if(approveRef.current){
                approveRef.current.disabled = false;
            }
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error")
        }
    }


    const submitForm = async (event)=>{
        event.preventDefault();

        if(btnRef.current){
            btnRef.current.disabled = true;
            btnRef.current.innerHTML = "Please Wait";
        }

        if(titleRef.current){
            const edittitle = titleRef.current.value;
            if(edittitle.includes('Rating Transition Study |') == false){
                if(btnRef.current){
                    btnRef.current.disabled = false;
                    btnRef.current.innerHTML = "Submit";
                    setalertType("error");
                    setalertOpen(true);
                    setalertmessage('Title should be named: Rating Transition Study | xyz-xyz');
                    return;
                }
    
            }
          }
    
        
    
        const fileData = new FormData();
        fileData.append("id", id);
        fileData.append("title", titleRef.current.value);
        
        if(reportRef.current.files[0] !== undefined && reportRef.current.files[0] !== null){
            fileData.append("file", reportRef.current.files[0]);
        }

        fileData.append("report_path", data.report_path);
        fileData.append("doc_type", typeRef.current.value);
        fileData.append("upload_date", dateRef.current.value);
    
        const res = await axios.post(
            `${url}/api/edit_announcement`,
            fileData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
    
        if(res.data.status == true){
    
            if(btnRef.current){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML = "Update";
            }

            setbool(!bool);
        
            setalertType("success");
            setalertOpen(true);
            setalertmessage(res.data.message);
        }
        else{
            
            if(btnRef.current){
                btnRef.current.disabled = false;
                btnRef.current.innerHTML = "Update";
            }
    
            setalertType("error");
            setalertOpen(true);
            setalertmessage(res.data.message);
        }
    
      }
    



  return (
    <div>
    
        <div style={{height: '90vh'}}>
            <div className='mt-4 col-10 mx-auto'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/critarea-announcement-dashboard"} >Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Announcment</li>
                        </ol>
                    </nav>
                    <hr/>
            </div>

            <form className='col-8 card mx-auto p-3 mt-5' onSubmit={(event)=>{submitForm(event)}}>
                <div className='fs-3 text-center fw-bold' style={{color: "#000078"}}>
                    Edit Announcments
                </div>
                <div className='mt-2'>
                    <div className='fs-6'>
                        Title:
                    </div>
                    <div className='mt-1'>
                        <input className='form-control' defaultValue={data.title} ref={titleRef} required />
                    </div>
                    <div className='fs-6 mt-3'>
                        Document Type:
                    </div>
                    <div className='mt-1'>
                        <select className='form-select' ref={typeRef} required>
                            <option value={'Rating Transitions/Movements'}>Rating Transitions/Movements</option>
                        </select>
                    </div>
                    <div className='fs-6 mt-3'>
                        Report (PDF/DOC):
                    </div>
                    <div className='mt-1'>
                        <input className='form-control' type='file' ref={reportRef} />
                        {(data.report_path !== null && data.report_path !== "") ?
                        <div>Uploaded File: <a href={`${url}/api/announcement_report/${data.report_path}`} target='_blank'>{data.report_path}</a></div> : null}
                    </div>
                    <div className='fs-6 mt-3'>
                        Uploaded Date:
                    </div>
                    <div className='mt-1'>
                        <input className='form-control' ref={dateRef} type='date' required/>
                    </div>
                    <Box className="mx-auto" sx={{ width: "98%" }}>
                        <Collapse in={alertopen}>
                            <Alert
                            severity={alertType}
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setalertOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2 }}
                            >
                            {AlertMessage}
                            </Alert>
                        </Collapse>
                    </Box>

                    <div className='mt-3'>
                        <button className='btn btn-primary' ref={btnRef} type='submit'>Update</button>
                        {Number(userid) == 42 && <button className='btn btn-success ms-1' type='button' hidden={true} ref={approveRef} onClick={()=>{approve()}}>Approve</button>}
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}
