import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { url } from '../../../../Config';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuidv4 } from "uuid";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";

// Initialize the exporting modules
Exporting(Highcharts);
ExportData(Highcharts);

 




export default function GenerateAssessmentGraph(props) {

    // Bar and Line
    const [data, setdata] = useState([]);
    const { id } = useParams();
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState('success');
    const [AlertMessage, setalertmessage] = useState('');
    const [typeSelect, settypeSelect] = useState('');
    const [category, setcategory] = useState([{ id: uuidv4() }]);  
    const [cat_arr, setcat_arr] = useState([]);
    const [series, setseries] = useState([{ id: uuidv4(), data: Array(cat_arr.length), yAxis: 1, zIndex: 0 }])
    const [demoseries, setdemoseries] = useState([{ id: uuidv4(), yAxis: 1, data: Array(cat_arr.length) }])
    const [yaxis, setyaxis] = useState(Array(2));
    const [yaxistext1, setyaxistext1] = useState(null);
    const [yaxistext2, setyaxistext2] = useState(null);
    const [categoryinc, setcategoryinc] = useState(0);
    const [title, settitle] = useState('');
    const [barStacking, setbarStacking] = useState(false);


    const [pietitle, setpietitle] = useState('');
    const [piedata, setpiedata] = useState([{id: uuidv4(), name: '', y: null}]);


    const chartRef = useRef(null);
    const chartRef2 = useRef(null);

  // Trigger context menu programmatically
  const openContextMenu = () => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      if (chart.exportSVGElements && chart.exportSVGElements[0]) {
        chart.exportSVGElements[0].element.onclick();
      } else {
        alert("Context menu button not found.");
      }
    }
  };



    const typeselectRef = useRef(null);
    const barchartRef = useRef(null);
    const piechartRef = useRef(null);


    // useEffect(()=>{

    // },[])


      const handleAddCat = () => {
            setcategory([...category, { id: uuidv4() }]);
      };
    
      const handleDeleteCat = (id, index) => {
        setcategory(category.filter((cat) => cat.id !== id));
        const newcat = [...cat_arr];
        newcat.splice(index, 1);
        setcat_arr(newcat)
      };

      const handleAddseries = () => {
        setseries([...series, { id: uuidv4(), data: Array(cat_arr.length), yAxis: 1, zIndex: 0 }]);
        setdemoseries([...demoseries, { id: uuidv4(), data: Array(cat_arr.length), yAxis: 1, zIndex: 0 }]);
      };
    
      const handleDeleteseries = (s_id, index) => {
        let delseries = series.filter((s) => s.id !== s_id);
        if(delseries.length > 0){
            delseries[0].yAxis = 1;
        }
        setseries(delseries);

        let deldemoseries = demoseries.filter((s) => s.id !== s_id);
        if(deldemoseries.length > 0){
            deldemoseries[0].yAxis = 1;
        }
        setdemoseries(deldemoseries);
      };

      
    const handleSwitchChange = () => {
        setbarStacking(!barStacking);
        for(let i in demoseries){
            if(demoseries[i].type == 'column'){
                document.getElementById(`stack${i}`).hidden = false;
            }
        }
      };

      const handleAddpieData = () => {
        setpiedata([...piedata, { id: uuidv4(), name: '', y: null}]);
      };

      const handleDeletepieData = (p_id, index) => {
        setpiedata(piedata.filter((s) => s.id !== p_id));
      };
  

      const chartOptions = {
        chart: {
            zooming: {
                type: 'xy'
            }
        },
        title: {
            text: title,
            align: 'left'
        },
        credits: {
            text: 'Source: ' +
                '<a href="https://www.pacra.com"' +
                'target="_blank">PACRA</a>'
        },
        exporting: {
            enabled: true, // Enable the context menu
            buttons: {
              contextButton: {
                menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                ],
              },
            },
          },
        
        xAxis: [{
            categories: cat_arr,
            crosshair: true
        }],
        yAxis: [
            { // Secondary yAxis
                    title: {
                        text: series.length > 1 ? series[1].yAxis == 1 ? null : yaxistext2 : null,
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: `{value}${series.length > 1 ? series[1].yAxis == 1 ? '' : series[1].unit : ''}`,
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
            }
            ,{ // Primary yAxis
                labels: {
                    format: `{value}${(series[0].unit == null || series[0].unit == undefined) ? '' : series[0].unit}`,

                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: yaxistext1,
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
                
            }
        ],

        tooltip: {
            shared: true
        },

        plotOptions: {
            column: {
              stacking: barStacking // Enable stacking for columns
            }
        },

        legend: {
            align: 'left',
            verticalAlign: 'top',
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        series: demoseries

      };

      const donutchartOptions = {
      
        chart: {
            type: 'pie',
        },
      
        title: {
            text: pietitle
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b> <br /> Value: <b>{point.y}</b>'
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
        },
        plotOptions: {
            series: {
                innerSize: '75%',
                dataLabels: {
                    enabled: true,
                    distance: -15,
                    format: '{point.percentage:.0f}%',

                },
              showInLegend: true
        
            }
        },
        series: [
            {
                name: 'Percentage',
                colorByPoint: true,
                data: piedata
            }
        ]

    };
    


    //   const stackedchartOptions = {
    //     chart: {
    //         zooming: {
    //           type: 'xy' // Enable zooming for both axes
    //         }
    //       },
    //       title: {
    //         text: 'Double Axis Chart: Stacked Columns with Line'
    //       },
    //       xAxis: {
    //         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    //         crosshair: true
    //       },
    //       yAxis: [
    //         { // Primary yAxis for stacked columns
    //           title: {
    //             text: 'Primary Axis (Stacked Values)'
    //           },
    //           labels: {
    //             format: '{value}'
    //           },
    //           stackLabels: {
    //             enabled: true,
    //           }
    //         },
    //         { // Secondary yAxis for the line
    //           title: {
    //             text: 'Secondary Axis (Line)'
    //           },
    //           opposite: true, // Place this axis on the right
    //           labels: {
    //             format: '{value}°C'
    //           }
    //         }
    //       ],
    //       tooltip: {
    //         shared: true
    //       },
    //       plotOptions: {
    //         column: {
    //           stacking: 'normal' // Enable stacking for columns
    //         }
    //       },
    //       series: [
    //         {
    //           name: 'Column Series 1',
    //           type: 'column',
    //           data: [5, 3, 4, 7, 2, 6, 8, 5, 7, 4, 3, 6],
    //           stack: 'stack1'
    //         },
    //         {
    //           name: 'Column Series 2',
    //           type: 'column',
    //           data: [-2, -1, -3, -2, -1, -3, -2, -1, -3, -2, -1, -3],
    //           stack: 'stack1'
    //         },
    //         {
    //           name: 'Line Series',
    //           type: 'line',
    //           data: [15, 18, 20, 25, 23, 19, 21, 24, 26, 22, 20, 18],
    //           yAxis: 1, // Use the secondary yAxis for this series
    //           tooltip: {
    //             valueSuffix: ' °C'
    //           }
    //         },
    //         {
    //           name: 'Line Series 2',
    //           type: 'line',
    //           data: [10, 28, 12, 15, 3, 9, 17, 2.4, 16, 22, 20, 18],
    //           // yAxis: 1, // Use the secondary yAxis for this series
    //           tooltip: {
    //             valueSuffix: ' °C'
    //           }
    //         }
    //       ]

    //   };
    

  return (
    <div>
       <div className='fs-3 text-center fw-bold' style={{color: "#000078"}}>
            Opinion
        </div>
       <div className='fs-5 text-center fw-bold' style={{color: "#000078"}}>
            Sub Heading
        </div>
        <div className='mt-3 p-2 fw-bold'>
            <div>
                Select Graph Type:
            </div>
            <div className='col-5 mt-1'>
                <select className='form-select' ref={typeselectRef} value={typeSelect} onChange={(e)=>{
                    settypeSelect(e.target.value);
                    if(e.target.value == 'Bar and Line'){
                        if(barchartRef.current){
                            barchartRef.current.hidden = false
                        }
                        if(piechartRef.current){
                            piechartRef.current.hidden = true
                        }

                    }
                    else if(e.target.value == 'Pie'){
                        if(barchartRef.current){
                            barchartRef.current.hidden = true
                        }
                        if(piechartRef.current){
                            piechartRef.current.hidden = false
                        }
                        
                    }
                    else{
                        if(barchartRef.current){
                            barchartRef.current.hidden = true
                        }
                        if(piechartRef.current){
                            piechartRef.current.hidden = true
                        }
                        

                    }
                   
                }}>
                    <option value={''}>Select Graph Type</option>
                    <option value={'Bar and Line'}>Bar and Line Chart</option>
                    <option value={'Pie'}>Donut Chart</option>
                </select>
            </div>
        </div>
        <div className='card mt-2 col-12' ref={barchartRef} hidden={true}>
            <form className='p-3 col-12'>
                <div className='fs-5 text-center fw-bold' style={{color: "#000078"}}>
                    Bar and Line Chart Form
                </div>
                <div className='p-2'>
                    <div className='fw-bold fs-6'>
                        Select Title:
                    </div>
                    <div className='col-6 mt-1'>
                        <input className='form-control' value={title} onChange={(e)=>{settitle(e.target.value)}} required />
                    </div>
                    {/* <div className='fw-bold fs-6 mt-2'>
                        Select Sub-Title:
                    </div>
                    <div className='col-6 mt-1'>
                        <input className='form-control' value={subtitle} onChange={(e)=>{setsubtitle(e.target.value)}} />
                    </div> */}

                    <div class="form-check form-switch" id='stackbar'>
                      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleSwitchChange} checked={barStacking} />
                      <label class="form-check-label" for="flexSwitchCheckChecked">Bar Stacking</label>
                    </div>

                    <div className='fw-bold fs-6 mt-2'>
                        Insert Category (X-axis):
                    </div>
                    <div className='mt-1'>
                        {
                            category.map((cat, index)=>{
                                const cat_id = cat.id;
                                const showAddButton = index === category.length - 1;
                          
                                return(
                                    <div className='d-flex' key={cat_id} id={`task-${cat_id}`}>
                                        <div className='col-6'>
                                            <input className='form-control mb-1'
                                            value={cat_arr[index]}
                                            onChange={(e) => {
                                              if (e.target.value !== "") {
                                                const newtitle = [...cat_arr];
                                                newtitle[index] = e.target.value
                                                setcat_arr(newtitle);
                                              } else {
                                                const newtitle = [...cat_arr];
                                                newtitle[index] = null
                                                setcat_arr(newtitle);
                                              }
                                            }} required/>
                                        </div>
                                        {category.length > 1 && (
                                        <div
                                        className="ms-1"
                                        onClick={() => handleDeleteCat(cat_id, index)}
                                        >
                                            <CancelIcon style={{color: "#dc3545"}} />
                                        </div>
                                        )}
                                        {showAddButton && (
                                            <div
                                            className="ms-1"
                                            onClick={handleAddCat}
                                            >
                                                <AddCircleOutlinedIcon style={{color: "#198754"}} />
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                    <div className='fw-bold fs-6 mt-2'>
                        Enter Series (Y-axis):
                    </div>
                    {
                        series.map((s, index)=>{
                            const series_id = s.id;
                            const showAddbtn = index === series.length - 1;

                            return(
                                <div className='card mt-1 mb-1 p-2'>
                                    {
                                        index == 1 &&
                                        <div className='fw-bold'>Define Second Axis here (Optional)</div>
                                    }
                                    <div className='mt-1'>
                                        Enter Title:
                                    </div>
                                    <div className='mt-1 col-6'>
                                        <input className='form-control ms-1' value={demoseries[index].name} onChange={(e)=>{
                                            const seriesinput = series;
                                            seriesinput[index].text = e.target.value;
                                            setseries(seriesinput);
                                            setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                            setseries(series.filter((s) => s.id !== 'demoid'));

                                            const demoinput = demoseries;
                                            demoinput[index].name = e.target.value;
                                            demoinput[index].id = seriesinput[index].id;
                                            setdemoseries(demoinput);
                                            setdemoseries([...demoseries, { id: 'demoid', data: Array(cat_arr.length) }]);
                                            setdemoseries(demoseries.filter((s) => s.id !== 'demoid'));
                                            

                                            
                                        }} required />
                                    </div>
                                    <div className='mt-1'>
                                        Select Type:
                                    </div>
                                    <div className='mt-1 col-6'>
                                        <select className='form-select' value={demoseries[index].type} onChange={(e)=>{
                                             const seriestype = series;
                                             seriestype[index].type = e.target.value;

                                             const demotype = demoseries;
                                             demotype[index].type = e.target.value;
                                             demotype[index].id = seriestype[index].id;
                                             if(barStacking == true){
                                                 if(e.target.value == 'column'){
                                                    demotype[index].zIndex = 0;
                                                    seriestype[index].zIndex = 0;
                                                    document.getElementById(`stack${index}`).hidden = false;
                                                 }
                                                 else if(e.target.value == 'spline'){
                                                    demotype[index].zIndex = 2;
                                                    seriestype[index].zIndex = 2;
                                                    document.getElementById(`stack${index}`).hidden = true;
    
                                                 }
                                                 else if(e.target.value == 'line'){
                                                    demotype[index].zIndex = 2;
                                                    seriestype[index].zIndex = 2;
                                                    document.getElementById(`stack${index}`).hidden = true;
    
                                                 }
                                             }

                                             setseries(seriestype);
                                             setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                             setseries(series.filter((s) => s.id !== 'demoid'));


                                             setdemoseries(demotype);
                                             setdemoseries([...demoseries, { id: 'demoid', data: Array(cat_arr.length) }]);
                                             setdemoseries(demoseries.filter((s) => s.id !== 'demoid'));

                                        }} required>
                                            <option value={''}>Select Type</option>
                                            <option value={'column'}>Bar</option>
                                            <option value={'line'}>Line</option>
                                            <option value={'spline'}>Curve Line</option>
                                        </select>
                                    </div>
                                    <div id={`stack${index}`} hidden={true}>
                                        <div className='mt-1'>
                                            Stack Name:
                                        </div>
                                        <div className='mt-1 col-6'>
                                            <input className='form-control' onChange={(e)=>{
                                                const series_stack = series;
                                                series_stack[index].stack = e.target.value;
                                                setseries(series_stack);
                                                setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                setseries(series.filter((s) => s.id !== 'demoid'));
                                                    
                                                
                                                const demo_stack = demoseries;
                                                demo_stack[index].id = series_stack[index].id;
                                                demo_stack[index].stack = e.target.value;
                                                setdemoseries(demo_stack);
                                                setdemoseries([...demoseries, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                setdemoseries(demoseries.filter((s) => s.id !== 'demoid'));
                                                
                                            }} />
                                        </div>
                                    </div>
                                    <div className='mt-1'>
                                        Unit:
                                    </div>
                                    <div className='mt-1 col-6'>
                                        <input className='form-control me-1' value={series[index].unit} onChange={(e)=>{
                                            const seriesunit = series;
                                            seriesunit[index].unit = e.target.value;
                                            setseries(seriesunit);
                                            setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                            setseries(series.filter((s) => s.id !== 'demoid'));
    

                                            const demounit = demoseries;
                                            demounit[index].id = seriesunit[index].id;
                                            demounit[index].tooltip = {valueSuffix: e.target.value};
                                            setdemoseries(demounit);
                                            setdemoseries([...demoseries, { id: 'demoid', data: Array(cat_arr.length) }]);
                                            setdemoseries(demoseries.filter((s) => s.id !== 'demoid'));

                                        }} />
                                    </div>
                                    <div>
                                        Enter Data:
                                    </div>
                                    <div className='row col-6'>
                                        {
                                            cat_arr.map((value2, index2)=>{
                                                return(
                                                    <div className='col'>
                                                        <input className='form-control me-1' type='number' value={series[index].data[index2]} onChange={(e)=>{
                                                            const seriesdata = series;
                                                            seriesdata[index].data[index2] = Number(e.target.value);

                                                            setseries(seriesdata);
                                                            setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                            setseries(series.filter((s) => s.id !== 'demoid'));

                                                            const demodata = demoseries;
                                                            demodata[index].data[index2] = Number(e.target.value);
                                                            demodata[index].id = seriesdata[index].id;

                                                            setdemoseries(demodata);
                                                            setdemoseries([...demoseries, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                            setdemoseries(demoseries.filter((s) => s.id !== 'demoid'));
                
                                                        }} required/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        index == 0 && 
                                        <>
                                            <div>
                                                Y-Axis Text
                                            </div>
                                            <div className='row col-6'>
                                                <input className='form-control' value={yaxistext1} onChange={(e)=>{setyaxistext1(e.target.value)}} />
                                            </div>
                                        </>
                                    }
                                    {
                                        index == 1 && 
                                        <>
                                            <div>
                                                Y-Axis Representation
                                            </div>
                                            <div className='row col-6'>
                                            <select className='form-select' value={demoseries[index].yAxis} onChange={(e)=>{
                                                    const seriesaxis = series;
                                                    seriesaxis[index].yAxis = Number(e.target.value);

                                                    const demoaxis = demoseries;
                                                    demoaxis[index].yAxis = Number(e.target.value);
                                                    demoaxis[index].id = seriesaxis[index].id;
                                                    if(e.target.value == 1){
                                                        for(let i in  seriesaxis){
                                                            if(i !== 0 && i !== 1){
                                                                seriesaxis[i].yAxis = 1;
                                                                demoaxis[i].yAxis = 1;
                                                            }
                                                        }
                                                    }

                                                    setseries(seriesaxis);
                                                    setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                    setseries(series.filter((s) => s.id !== 'demoid'));

                                                    setdemoseries(demoaxis);
                                                    setdemoseries([...demoaxis, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                    setdemoseries(demoaxis.filter((s) => s.id !== 'demoid'));



                                                }} required>
                                                    <option value={0}>Zero</option>
                                                    <option value={1}>One</option>
                                                </select>
                                            </div>
                                            <div>
                                                Y-Axis Text
                                            </div>
                                            <div className='row col-6'>
                                                <input className='form-control' value={yaxistext2} onChange={(e)=>{setyaxistext2(e.target.value)}}/>
                                            </div>
                                        </>
                                    }
                                    {
                                        (index !== 0 && index !== 1) ?
                                        demoseries.length > 1 ?
                                        demoseries[1].yAxis == 0 ?
                                        <>
                                            <div>
                                                Y-Axis Representation
                                            </div>
                                            <div className='row col-6'>
                                                <select className='form-select' value={demoseries[index].yAxis} onChange={(e)=>{
                                                    const seriesaxis = series;
                                                    if(demoseries.length > 1){
                                                        if(demoseries[1].yAxis == 1){
                                                            seriesaxis[index].yAxis = 1;
                                                        }
                                                        else{
                                                            seriesaxis[index].yAxis = Number(e.target.value);
                                                        }
                                                    }
                                                    
                                                    setseries(seriesaxis);
                                                    setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                    setseries(series.filter((s) => s.id !== 'demoid'));


                                                    const demoaxis = demoseries;
                                                    demoaxis[index].yAxis = Number(e.target.value);
                                                    demoaxis[index].id = seriesaxis[index].id;

                                                    setdemoseries(demoaxis);
                                                    setdemoseries([...demoaxis, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                    setdemoseries(demoaxis.filter((s) => s.id !== 'demoid'));



                                                }} required>
                                                    <option value={0}>Zero</option>
                                                    <option value={1}>One</option>
                                                </select>
                                            </div>

                                        </>
                                        : null : null : null

                                    }

                                            <div>
                                                Select Color
                                            </div>
                                            <div className='row col-6'>
                                            <select className='form-select' value={demoseries[index].color} onChange={(e)=>{
                                                    const seriescolor = series;
                                                    seriescolor[index].color = e.target.value;
                                                    setseries(seriescolor);
                                                    setseries([...series, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                    setseries(series.filter((s) => s.id !== 'demoid'));

                                                    const democolor = demoseries;
                                                    democolor[index].color = e.target.value;
                                                    democolor[index].id = seriescolor[index].id;
                                                    setdemoseries(democolor);
                                                    setdemoseries([...democolor, { id: 'demoid', data: Array(cat_arr.length) }]);
                                                    setdemoseries(democolor.filter((s) => s.id !== 'demoid'));

                                                }}>
                                                    <option value={''}>Select Color</option>
                                                    <option value={'red'}>Red</option>
                                                    <option value={'blue'}>Blue</option>
                                                    <option value={'green'}>Green</option>
                                                    <option value={'orange'}>Orange</option>
                                                    <option value={'yellow'}>Yellow</option>
                                                    <option value={'black'}>Black</option>
                                                    <option value={'purple'}>Purple</option>
                                                    <option value={'brown'}>Brown</option>
                                                    <option value={'white'}>White</option>
                                                    <option value={'grey'}>Grey</option>
                                                    <option value={'darkred'}>Dark Red</option>
                                                    <option value={'darkgreen'}>Dark Green</option>
                                                    <option value={'darkblue'}>Dark Blue</option>
                                                    <option value={'skyblue'}>Sky Blue</option>
                                                </select>
                                            </div>
                                    
                                   

                                    <div className='mt-1'>
                                        {showAddbtn && (
                                        <button className='btn btn-success me-1' onClick={handleAddseries} type='button'>Add</button>
                                        )}
                                        {series.length > 1 && (
                                            <button className='btn btn-danger me-1' type='button' onClick={() => handleDeleteseries(series_id, index)}>Remove</button>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    }

                    <div className='p-2'>
                        <table className='table table-bordered border-dark'>
                            <tbody>
                                <tr className='table-secondary'>
                                    <td></td>
                                    {
                                        cat_arr.map((value, index)=>{
                                            return(
                                                <td><b>{value}</b></td>
                                            )
                                        })
                                    }
                                </tr>
                                {
                                    series.map((value, index)=>{
                                        return(
                                            <tr>
                                                <td>{value.text}</td>
                                                {
                                                    value.data.map((value2, index2)=>{
                                                        return(
                                                            <td>{value2}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div>
                    <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />
                    </div>

                </div>
            </form>
            
        </div>

        {/* <div className='mt-2' ref={stackedbarchartRef} hidden={true}>
            <form className='mx-auto p-3'>
                <div className='fs-3 text-center fw-bold' style={{color: "#000078"}}>
                    Stacked Bars and Line Chart Form
                </div>
                <div className='p-2'>

                    <HighchartsReact highcharts={Highcharts} options={stackedchartOptions} />

                </div>
            </form>
        </div> */}

        <div className='mt-2' ref={piechartRef} hidden={true}>
            <form className='mx-auto p-3'>
                <div className='fs-3 text-center fw-bold' style={{color: "#000078"}}>
                    Donut Chart Form
                </div>
                <div className='p-2'>
                <div className='fw-bold fs-6'>
                        Select Title:
                </div>
                <div className='col-6 mt-1'>
                    <input className='form-control' value={pietitle} onChange={(e)=>{setpietitle(e.target.value)}} required />
                </div>
                <div className='fw-bold fs-6 mt-2'>
                    Enter Chart Data:
                </div>
                <div>
                    {
                        piedata.map((p, index)=>{
                            const pie_id = p.id;
                            const showAddbtn = index === piedata.length - 1;

                            return(
                                <div className='' key={pie_id} id={`task2-${pie_id}`}>
                                    <div>
                                        Name:
                                    </div>
                                    <div className='mt-1 col-6'>
                                        <input className='form-control' value={piedata[index].name} onChange={(e)=>{
                                            let piename = piedata;
                                            piename[index].name = e.target.value;
                                            setpiedata(piename);

                                            setpiedata([...piedata, { id: 'demoid', name: '', y: null }]);
                                            setpiedata(piedata.filter((p) => p.id !== 'demoid'));

                                        }} />
                                    </div>
                                    <div>
                                        Value:
                                    </div>
                                    <div className='mt-1 col-6'>
                                        <input className='form-control' type='number' value={piedata[index].y} onChange={(e)=>{
                                            let pie = piedata;
                                            pie[index].y = Number(e.target.value);
                                            setpiedata(pie);

                                            setpiedata([...piedata, { id: 'demoid', name: '', y: null }]);
                                            setpiedata(piedata.filter((p) => p.id !== 'demoid'));

                                        }} />
                                    </div>
                                    <div>
                                        Select Color
                                    </div>
                                    <div className='row col-6'>
                                        <select className='form-select' value={piedata[index].color} onChange={(e)=>{
                                            const piecolor = piedata;
                                            piecolor[index].color = e.target.value;
                                            setpiedata(piecolor);

                                            setpiedata([...piedata, { id: 'demoid', name: '', y: null }]);
                                            setpiedata(piedata.filter((p) => p.id !== 'demoid'));

                                        }}>
                                            <option value={''}>Select Color</option>
                                            <option value={'red'}>Red</option>
                                            <option value={'blue'}>Blue</option>
                                            <option value={'green'}>Green</option>
                                            <option value={'orange'}>Orange</option>
                                            <option value={'yellow'}>Yellow</option>
                                            <option value={'black'}>Black</option>
                                            <option value={'purple'}>Purple</option>
                                            <option value={'brown'}>Brown</option>
                                            <option value={'white'}>White</option>
                                            <option value={'grey'}>Grey</option>
                                            <option value={'silver'}>Silver</option>
                                            <option value={'darkred'}>Dark Red</option>
                                            <option value={'darkgreen'}>Dark Green</option>
                                            <option value={'darkblue'}>Dark Blue</option>
                                            <option value={'skyblue'}>Sky Blue</option>
                                            <option value={'darkgrey'}>Dark Grey</option>
                                        </select>
                                    </div>


                                    <div className='mt-1'>
                                        {showAddbtn && (
                                        <button className='btn btn-success me-1' onClick={handleAddpieData} type='button'>Add</button>
                                        )}
                                        {piedata.length > 1 && (
                                            <button className='btn btn-danger me-1' type='button' onClick={() => handleDeletepieData(pie_id, index)}>Remove</button>
                                        )}
                                    </div>

                                </div>

                            )

                        })
                    }
                </div>

                </div>
                <div>
                    {/* <table className='table table-bordered border-dark'>
                        <
                    </table> */}
                </div>
                <div>
                    <HighchartsReact highcharts={Highcharts} options={donutchartOptions} ref={chartRef2} />
                </div>
            </form>
        </div>

        <br /> <br />
    </div>
  )
}
