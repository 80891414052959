import React, {useState, useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { url } from '../../../Config';
import { Link } from 'react-router-dom';

export default function AddAnnouncement(props) {


  const [alertopen, setalertOpen] = useState(false);
  const [alertType, setalertType] = useState('success');
  const [AlertMessage, setalertmessage] = useState('');
  const [title, settitle] = useState('');


  const titleRef = useRef(null);
  const typeRef = useRef(null);
  const reportRef = useRef(null);
  const dateRef = useRef(null);
  const btnRef = useRef(null);




  const submitForm = async (event)=>{
    event.preventDefault();
    if(btnRef.current){
        btnRef.current.disabled = true;
        btnRef.current.innerHTML = "Please Wait";
    }

    const fileData = new FormData();
    fileData.append("title", titleRef.current.value);
    fileData.append("file", reportRef.current.files[0]);
    fileData.append("doc_type", typeRef.current.value);
    fileData.append("upload_date", dateRef.current.value);

    const res = await axios.post(
        `${url}/api/add_announcement`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

    if(res.data.status == true){

        if(btnRef.current){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Submit";
        }
    
        setalertType("success");
        setalertOpen(true);
        setalertmessage(res.data.message);
    }
    else{

        if(btnRef.current){
            btnRef.current.disabled = false;
            btnRef.current.innerHTML = "Submit";
        }

        setalertType("error");
        setalertOpen(true);
        setalertmessage(res.data.message);
    }

  }


  return (
    <div style={{height: '90vh'}}>

        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/announcement-dashboard"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Announcment</li>
                </ol>
            </nav>
            <hr/>
        </div>

      <form className='col-8 card mx-auto p-3 mt-5' onSubmit={(event)=>{submitForm(event)}}>
        <div className='fs-3 text-center fw-bold' style={{color: "#000078"}}>
            PACRA Announcments Form
        </div>
        <div className='mt-2'>
            <div className='fs-6 mt-3'>
                Document Type:
            </div>
            <div className='mt-1'>
                <select className='form-select' ref={typeRef} required onChange={(e)=>{
                    if(e.target.value == 'Rating Transitions/Movements'){
                        if(titleRef.current){
                            titleRef.current.disabled = true;
                            titleRef.current.value = 'Private Ratings | Movement';
                        }
                    }
                    else{
                        if(titleRef.current){
                            titleRef.current.disabled = false;
                            titleRef.current.value = '';
                        }
                    }
                }}>
                    <option value={''}>Select Document Type</option>
                    <option value={'Rating Transitions/Movements'}>Rating Transitions/Movements</option>
                    <option value={'Best Practices Guiding Documents'}>Best Practices Guiding Documents</option>
                    <option value={'Financial Statement'}>Financial Statement</option>
                    <option value={'Directors Report'}>Directors Report</option>
                </select>
            </div>
            <div className='fs-6'>
                Title:
            </div>
            <div className='mt-1'>
                <input className='form-control' ref={titleRef} required />
            </div>

            <div className='fs-6 mt-3'>
                Report (PDF/DOC):
            </div>
            <div className='mt-1'>
                <input className='form-control' ref={reportRef} type='file' required/>
            </div>
            <div className='fs-6 mt-3'>
                Uploaded Date:
            </div>
            <div className='mt-1'>
                <input className='form-control' ref={dateRef} type='date' required/>
            </div>
            <Box className="mx-auto" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>

            <div className='mt-3'>
                <button className='btn btn-primary' ref={btnRef} type='submit'>Submit</button>
            </div>
        </div>
      </form>
    </div>
  )
}
