import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { url } from '../../../../Config';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuidv4 } from "uuid";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";

// Initialize the exporting modules
Exporting(Highcharts);
ExportData(Highcharts);


export default function TestbarGraph(props) {

    const [data, setdata] = useState(null);
    const [chartOptions, setchartOptions] = useState({})

    useEffect(()=>{

        const func = async ()=>{
            const res = await axios.get(`${url}/api/show_graph`);
            const resp = res.data;
            const chartOption = {
                chart: {
                    zooming: {
                        type: 'xy'
                    }
                },
                title: {
                    text: resp.title,
                    align: 'left'
                },
                credits: {
                    text: 'Source: ' +
                        '<a href="https://www.pacra.com"' +
                        'target="_blank">PACRA</a>'
                },
                exporting: {
                    enabled: true, // Enable the context menu
                    buttons: {
                      contextButton: {
                        menuItems: [
                          "viewFullscreen",
                          "printChart",
                          "downloadPNG",
                          "downloadJPEG",
                          "downloadPDF",
                          "downloadSVG",
                        ],
                      },
                    },
                  },
                
                xAxis: [{
                    categories: resp.category,
                    crosshair: true
                }],
                yAxis: [
                    { // Secondary yAxis
                            title: {
                                text: resp.series.length > 1 ? resp.series[1].yAxis == 1 ? null : resp.y_axis_left : null,
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            labels: {
                                format: `{value}${resp.series.length > 1 ? resp.series[1].yAxis == 1 ? '' : resp.series[1].tooltip.valueSuffix : ''}`,
                                style: {
                                    color: Highcharts.getOptions().colors[0]
                                }
                            },
                            opposite: true
                    }
                    ,{ // Primary yAxis
                        labels: {
                            format: `{value}${(resp.series[0].tooltip.valueSuffix == null || resp.series[0].tooltip.valueSuffix == undefined) ? '' : resp.series[0].tooltip.valueSuffix}`,
        
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        title: {
                            text: resp.y_axis_right,
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        }
                        
                    }
                ],
        
                tooltip: {
                    shared: true
                },
        
                plotOptions: {
                    column: {
                      stacking: resp.bar_stacking // Enable stacking for columns
                    }
                },
        
                legend: {
                    align: 'left',
                    verticalAlign: 'top',
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        'rgba(255,255,255,0.25)'
                },
                series: resp.series
        
            };
        
            setchartOptions(chartOption);
            setdata(resp);
        }

        func();

    },[])


    // const chartOption = {
    //     chart: {
    //         zooming: {
    //             type: 'xy'
    //         }
    //     },
    //     title: {
    //         text: data.title,
    //         align: 'left'
    //     },
    //     credits: {
    //         text: 'Source: ' +
    //             '<a href="https://www.pacra.com"' +
    //             'target="_blank">PACRA</a>'
    //     },
    //     exporting: {
    //         enabled: true, // Enable the context menu
    //         buttons: {
    //           contextButton: {
    //             menuItems: [
    //               "viewFullscreen",
    //               "printChart",
    //               "downloadPNG",
    //               "downloadJPEG",
    //               "downloadPDF",
    //               "downloadSVG",
    //             ],
    //           },
    //         },
    //       },
        
    //     xAxis: [{
    //         categories: data.category,
    //         crosshair: true
    //     }],
    //     yAxis: [
    //         { // Secondary yAxis
    //                 title: {
    //                     text: data !== null && data.series.length > 1 ? data.series[1].yAxis == 1 ? null : data.y_axis_left : null,
    //                     style: {
    //                         color: Highcharts.getOptions().colors[0]
    //                     }
    //                 },
    //                 labels: {
    //                     format: `{value}${data !== null && data.series.length > 1 ? data.series[1].yAxis == 1 ? '' : data.series[1].tooltip.valueSuffix : ''}`,
    //                     style: {
    //                         color: Highcharts.getOptions().colors[0]
    //                     }
    //                 },
    //                 opposite: true
    //         }
    //         ,{ // Primary yAxis
    //             labels: {
    //                 format: `{value}${(data !== null && data.series[0].tooltip.valueSuffix == null || data.series[0].tooltip.valueSuffix == undefined) ? '' : data.series[0].tooltip.valueSuffix}`,

    //                 style: {
    //                     color: Highcharts.getOptions().colors[1]
    //                 }
    //             },
    //             title: {
    //                 text: data !== null && data.y_axis_right,
    //                 style: {
    //                     color: Highcharts.getOptions().colors[1]
    //                 }
    //             }
                
    //         }
    //     ],

    //     tooltip: {
    //         shared: true
    //     },

    //     plotOptions: {
    //         column: {
    //           stacking: data !== null && data.bar_stacking // Enable stacking for columns
    //         }
    //     },

    //     legend: {
    //         align: 'left',
    //         verticalAlign: 'top',
    //         backgroundColor:
    //             Highcharts.defaultOptions.legend.backgroundColor || // theme
    //             'rgba(255,255,255,0.25)'
    //     },
    //     series: data !== null && data.series

    //   };


  return (
    <div>
        {
            data !== null ?
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            : 'Please Wait'
        }
    </div>
  )
}
